<template>
    <div class="sgirot-crud-list"
         :class="{'rtl' : isLanguageRtl()}">

        <bar-loader :active="aboutToLoadData"
                    :fixed="true"></bar-loader>

        <div class="toolbar uk-flex uk-flex-between">

            <div class="filters uk-flex uk-flex-wrap uk-flex-gap-small">
                <form-button  icon="search" @click="filtersExpanded = true" v-if="collapsableFiltersOnMobile && isMobile && ! filtersExpanded">{{translate('sgirot.list.expandFilters')}}</form-button>
                <form-button  icon="close" @click="filtersExpanded = false" v-if="collapsableFiltersOnMobile && isMobile && filtersExpanded">{{translate('sgirot.list.collapseFilters')}}</form-button>
                <div class="filters-inner uk-flex uk-flex-wrap uk-flex-gap-small" v-if="! isMobile" v-show=" ! isMobile || filtersExpanded">
                    <form-input
                        v-for="(conf, filterKey) of filterFields"
                        :key="filterKey"
                        :conf="conf"
                        v-bind="conf"
                        :name="'filter-source-'+filterKey"
                        :label="false"
                        class="form-filter-field"
                        v-model="desiredFilters[filterKey]" ></form-input>
                </div>

            </div>
            <div class="actions">
                <div class="uk-button-group uk-gap-s" v-if="enableActions">
                    <form-button
                    v-if="$store.getters['user/profile'].isSuperAdmin || $store.getters['user/profile'].isExpert"
                    @click="$router.push({name: subject + '-create'});">{{translate('sgirot.'+subject+'.listAdd')}}</form-button>
                    <slot name="massActions" :selectedItems="selectedItems" v-if="selectedItems.length > 0"></slot>
                </div>
            </div>
        </div>

        <div class="filters-mobile uk-flex uk-flex-wrap uk-flex-gap-small" v-if="isMobile" v-show=" ! isMobile || filtersExpanded || ! collapsableFiltersOnMobile">
            <form-input
                v-for="(conf, filterKey) of filterFields"
                :key="filterKey"
                :conf="conf"
                v-bind="conf"
                :name="'filter-source-'+filterKey"
                :label="false"
                class="form-filter-field"
                v-model="desiredFilters[filterKey]" ></form-input>
        </div>
<!--
        <pre class="uk-background-muted uk-padding-small" style="direction: ltr;">
               {{listData}}
        </pre>-->

        <skeleton :template="'lines'"
                  :count="10"
                  :fitHeight="false"
                  :defaultHeight="500"
                  :contentReady=" ! asyncOps.asyncStatus.asyncDataLoading">
            <div class="list-table uk-margin-top"   v-if="displayItems.length > 0 && ( ! blocksOnMobile || ! isMobile)">
                <div class="uk-overflow-auto">
                    <table class="uk-table  uk-table-striped  uk-table-divider" >
                        <thead>
                        <tr :class="{'sort-disabled': !enableSort}">
                            <th class="uk-table-shrinkcol-header-id"
                                 v-if="enableMassActions">
                                <input type="checkbox"
                                       v-if="areAllItemsSelected"
                                       checked="true"
                                        @click.prevent="deselectAllItems()">
                                <input type="checkbox"
                                       v-else
                                       @click.prevent="selectAllItems()">
                            </th>

                            <th class="uk-table-shrink col-header-sortable col-header-id"
                                v-if="enableIdColumn"
                                :class="{'col-header-sortable' : isColumnSortable('id')}"
                                @click="setSorting('id')" >
                                <span>{{ translate('core.id') }}</span>
                                <i class="col-sort-icon"
                                   uk-icon="icon:triangle-up;ratio:0.8"
                                   v-if="itemSorting.key === 'id' && itemSorting.direction === 'asc'"/>
                                <i class="col-sort-icon"
                                   uk-icon="icon:triangle-down;ratio:0.8"
                                   v-if="itemSorting.key === 'id' && itemSorting.direction === 'desc'"/>
                            </th>
                            <th class=""
                                :class="{'col-header-sortable' : isColumnSortable(col)}"
                                    v-for="(col, colIndex) of displayColumns"
                                :key="colIndex"

                                @click="setSorting(col)">
                                <span>{{translate('sgirot.'+subject+'.fields.'+col+'ListColumn')}}</span>
                                <i class="col-sort-icon"
                                   uk-icon="icon:triangle-up;ratio:0.8" v-if="itemSorting.key === col && itemSorting.direction === 'asc'"/>
                                <i class="col-sort-icon"
                                   uk-icon="icon:triangle-down;ratio:0.8" v-if="itemSorting.key === col && itemSorting.direction === 'desc'"/>
                            </th>
                            <th class="uk-table-shrink" v-if="enableActions">{{ translate('sgirot.general.actions') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, itemIndex) of displayItems" :key="itemIndex" :item="item">
                            <td class="uk-table-shrink col-header-sortable col-header-id"  v-if="enableMassActions">
                                <input type="checkbox"
                                       v-if="isItemSelected(item)"
                                       checked
                                       @click.prevent="deselectItem(item)">
                                <input type="checkbox"
                                       v-else
                                       @click.prevent="selectItem(item)">
                            </td>
                            <td class="uk-table-shrink uk-text-center" v-if="enableIdColumn">{{ item.id }}</td>
                            <td v-for="(col, colIndex) of displayColumns" :key="colIndex">
                                <slot name="cellContent" :item="item" :col="col" :col-index="colIndex">
                                    <span v-if=" ! columnIsLinkable(col)">{{ getCellValue(col, item[col]) }}</span>
                                    <a href="#" @click.prevent="showItem(item)" v-if="columnIsLinkable(col)">TEST{{ getCellValue(col, item[col]) }}</a>
                                </slot>
                            </td>
                            <td v-if="enableActions" >
                                <div class="action-list uk-flex uk-flex-start uk-gap-s">
                                    <div class="item-action uk-flex uk-flex-column uk-flex-middle uk-flex-center"
                                    v-if="$store.getters['user/profile'].isSuperAdmin || $store.getters['user/profile'].isExpert"
                                         @click="editItem(item)">
                                        <i class="" uk-icon="icon:pencil;ratio: 0.8"></i>
                                        <span class="">{{ translate('sgirot.general.edit') }}</span>
                                    </div>
                                    <div class="item-action uk-flex uk-flex-column uk-flex-middle uk-flex-center"
                                    v-if="$store.getters['user/profile'].isSuperAdmin"
                                         @click="promptDeleteItem(item)">
                                        <i  class="" uk-icon="icon:trash;ratio: 0.8"></i>
                                        <span class="item-action__text">{{ translate('sgirot.general.delete') }}</span>
                                    </div>
                                    <slot name="rowActions" :item="item"></slot>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <div class="list-table uk-margin-top"   v-if="displayItems.length > 0 && isMobile && blocksOnMobile">
                <div class="items">

                        <div v-for="(item, itemIndex) of displayItems"
                             :key="itemIndex"
                             :item="item"
                             class="item-mobile-card" >

                            <slot name="mobileListItem" :item="item">
                                <div class="item-data">
                                    <div  v-for="(col, colIndex) of displayColumns"
                                          :key="colIndex">

                                        <div class="item-data ">
                                            <slot name="cellContent" :item="item" :col="col" :col-index="colIndex">
                                                {{ getCellValue(col, item[col]) }}
                                            </slot>
                                        </div>

                                    </div>
                                </div>

                                <div class="action-list uk-flex uk-flex-start uk-gap-s">
                                    <div class="item-action uk-flex uk-flex-column uk-flex-middle uk-flex-center"
                                         v-if="$store.getters['user/profile'].isSuperAdmin || $store.getters['user/profile'].isExpert"
                                         @click="editItem(item)">
                                        <i class="" uk-icon="icon:pencil;ratio: 0.8"></i>
                                        <span class="">{{ translate('sgirot.general.edit') }}</span>
                                    </div>
                                    <div class="item-action uk-flex uk-flex-column uk-flex-middle uk-flex-center"
                                         v-if="$store.getters['user/profile'].isSuperAdmin"
                                         @click="promptDeleteItem(item)">
                                        <i  class="" uk-icon="icon:trash;ratio: 0.8"></i>
                                        <span class="item-action__text">{{ translate('sgirot.general.delete') }}</span>
                                    </div>
                                    <slot name="rowActions" :item="item"></slot>
                                </div>
                            </slot>

                        </div>



                    </div>
            </div>


            <h2 v-if="displayItems.length < 1">{{ translate('sgirot.general.emptyListMessage') }}</h2>

            <div class="pagination">


                <pagination
                        :forceShow="true"
                        style="display: block;"
                        class="uk-margin-top"
                        v-bind="paginationData"

                        v-model:currentPage="itemPagination.start"
                ></pagination>
                <div class="uk-container uk-container-s uk-margin-auto uk-flex uk-flex-center">
                    <form-input type="select"
                                style="max-width: 170px;margin: auto;"
                                :auto-translate="false"
                                v-model="itemPagination.limit"
                                :null-option="{
                                value: 1,
                                label: translate('sgirot.general.selectPaginationPlaceholder'),
                                disabled: true,
                           }"
                                :list="[
                    {
                        value: 50,
                        label: translate('sgirot.general.selectPaginationItem', {count: '50'}),
                    },
                    {
                        value: 100,
                       label: translate('sgirot.general.selectPaginationItem', {count: '100'}),
                    },
                    {
                        value: 500,
                         label: translate('sgirot.general.selectPaginationItem', {count: '500'}),
                    },
                    {
                        value: 1000,
                         label: translate('sgirot.general.selectPaginationItem', {count: '1000'}),
                    },
                ]"
                    ></form-input>
                </div>

            </div>
        </skeleton>

    </div>
</template>

<script>
import asyncOperations  from '@/client/extensions/composition/asyncOperations.js';
import {useStore} from "vuex";
import {computed, nextTick, watchEffect} from "vue";


export default {
    setup (props, setupContext ) {
        let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());

        return {asyncOps, asyncOpsReady, asyncStatus,}
    },
    props: {
        subject: {
            type: String,
            default: 'source'
        },
        apiSource: {
            type: [String, Boolean],
            default: false,
        },
        filterDelay: {
            type: Number,
            default: 500,
        },
        filterFields : {
            type: [Boolean, Object],
            default: false
        },
        tableColumns: {
            type: [Boolean, Object],
            default: false
        },
        enableMassActions : {
            type: Boolean,
            default: false,
        },
        enableActions : {
            type: Boolean,
            default: true,
        },
        enableIdColumn : {
            type: Boolean,
            default: true,
        },
        enableRowActions : {
            type: Boolean,
            default: true,
        },
        enableSort : {
            type: Boolean,
            default: true,
        },
        blocksOnMobile : {
            type: Boolean,
            default: false,
        },
        collapsableFiltersOnMobile : {
            type: Boolean,
            default: false,
        }

    },
    data: function () {
        this.lazyCreateCookie();
        let cookie = this.$cookie.get(this.cookieName);

        let itemFilters = cookie.itemFilters ?? {},
            desiredFilters = {...itemFilters},
            itemSorting = cookie.itemSorting ?? {
                key: 'id',
                direction: 'desc',
            },
            itemPagination = cookie.itemPagination ?? {
                start: 0,
                limit: 50,
            };


        return {

            itemFilters,
            desiredFilters,
            itemSorting,
            itemPagination,
            listData: {
                totalCount: 0,
                items : [],
                form: false,
                columns: false,
            },
            selectedItems : [],
            filterTimeout: null,
            aboutToLoadData : false,
            isMobile: false,
            filtersExpanded: false,
            asyncData : {
                listData: {
                    target : computed(() => {
                        if (this.apiSource) {
                            return this.apiSource;
                        }
                        return  this.subject;
                    }),
                    data : computed(() => {
                        return {
                            pagination : this.itemPagination,
                            filters    : this.itemFilters,
                            ordering   : this.itemSorting
                        }
                    }),
                    options : {
                        method: 'get',
                    },
                },
            },
        };
    },
    computed: {
        paginationData () {
            return {
                'totalCount' : this.listData.totalCount,
                'currentPage': this.itemPagination.start,
                'pageSize' : this.itemPagination.limit,
            }
        },
        displayColumns () {
            if (this.tableColumns) {
                return this.getDisplayColumnsByColumnProp();
            } else {
                return this.getDisplayColumnsByForm();
            }

        },
        displayItems () {
            if (this.tableColumns) {
                return this.getDisplayItemByColumnProp();
            }else {
                return this.getDisplayItemsByForm();
            }

        },
        filterFields () {
            if (this.tableColumns) {
                return this.getDisplayFiltersByColumnProp();
            }
            if ( ! this.listData.form) {
                return {};
            }

            let fields = Object.assign(this.listData.form.fields);
            let result = {};

            for (const [key, field] of Object.entries(fields)) {
                let filterable = field.filterable || false;

                if (filterable) {
                    result[key] = {
                        label:  field.label,
                        type: filterable.type || 'text',
                        placeholder: filterable.placeholder ?? '',
                        ...filterable
                    };
                }
            }

            return result;
        },
        cookieName() {
            return 'sgirot-list-'+this.subject+'-state';
        },
        areAllItemsSelected() {
            return  this.selectedItems.length == this.displayItems.length;
        },
    },
    emits: ['list:filtersUpdated','list:dataUpdated'],
    methods: {
        toggleAllItemsSelection() {
            if (this.areAllItemsSelected) {
                this.deselectAllItems();
            } else {
                this.selectAllItems();
            }
        },
        deselectAllItems() {
            this.selectedItems = [];
        },
        selectAllItems() {
            this.selectedItems = [...this.displayItems];
        },
        isColumnSortable(col) {
            if ( ! this.tableColumns || ! this.tableColumns[col]) {
                return true;
            }

            if ( this.tableColumns[col].hasOwnProperty('sortable') && ! this.tableColumns[col].sortable) {
                 return false;
            }

            return true;

        },
        selectItem (item) {
            if (this.isItemSelected(item)) {
                return true;
            }

            this.selectedItems = [...this.selectedItems, item];
        },
        deselectItem (item) {
            let newItems = [];
            this.selectedItems = this.selectedItems.map(targetItem => {
                if (targetItem.id !== item.id) {
                    newItems.push(targetItem);
                }

            });
            this.selectedItems = [...newItems];
        },
        toggleItemSelection(item) {
            if (this.isItemSelected(item)) {
                this.deselectItem(item);
            } else {
                this.selectItem(item);
            }
        },
        isItemSelected(item) {
            let result = false;

            this.selectedItems.forEach (targetItem => {
                if (targetItem.id === item.id) {
                    result = true;
                    return false;
                }
            });
            return result;
        },
        getDisplayColumnsByForm () {
            let result = [];

            if ( ! this.listData.form) {
                return result;
            }

            for (const [key, value] of Object.entries(this.listData.form.fields)) {
                if ( ! this.listData.form?.fields[key]?.itemListOptions) {
                    continue;
                }

                if (this.listData.form.fields[key].itemListOptions.show ){
                    result.push(key);
                }
            }

            return result;
        },
        getDisplayColumnsByColumnProp (withOptions = false) {
            let result = [];
            let resultWithOptions  = {}
            for (const [key, conf] of Object.entries({...this.tableColumns})){
                if ( ! this.isMobile || ! this.blocksOnMobile || conf.showInMobileBlock) {
                    result.push(key);
                    resultWithOptions[key] = conf;
                }


            }

            return withOptions ? resultWithOptions : result;

        },
        getDisplayItemsByForm () {

            let result = [];
            let specialFields = ['id','order','state','createdAt','updatedAt'];

            if ( !  this.listData.items) {
                return result;
            }
            // parse and add each item
            this.listData.items.forEach((item, index) => {

                let displayItem = {
                    id : item.id,
                };

                for (const [key, value] of Object.entries(item)) {
                    // skip special fields
                    if (specialFields.includes(key)) {
                        continue;
                    }

                    if ( ! this.listData.form?.fields[key]?.itemListOptions) {
                        continue;
                    }

                    if (this.listData.form.fields[key].itemListOptions.show ){
                        displayItem[key] = item[key];
                    }


                }

                result.push(displayItem);
            });

            return result;
        },
        getDisplayItemByColumnProp() {
            if ( ! Array.isArray(this.listData.items)) {
                return [];
            }

            return this.listData.items.map(item => {
                let parsedItem = {id : item.id};

                for (const [key, conf] of Object.entries({...this.tableColumns})) {
                    let targetKey = conf?.dataKey ? conf.dataKey : key;
                    parsedItem[targetKey] = item[targetKey] || null;
                }

                return parsedItem;
            });
        },
        getDisplayFiltersByColumnProp() {
            if ( ! this.filterFields) {
                return {};
            }

            return this.filterFields;
        },
        forceSetSorting(val)  {
            this.itemSorting = val;
        },
        setSorting(key) {
            if( ! this.enableSort || ! this.isColumnSortable(key)) {
                return;
            }
            if(this.itemSorting.key === key) {
                this.itemSorting.direction = this.itemSorting.direction === 'asc' ? 'desc' : 'asc';
                return;
            }

            this.itemSorting.key = key;
            this.itemSorting.direction = 'asc';
        },
        lazyCreateCookie () {
            try {
                let val = this.$cookie.get(this.cookieName);

                if ( ! val) {
                    this.$cookie.set(this.cookieName, {
                        itemPagination: {
                            start: 0,
                            limit: 50,
                        },
                        itemFilters: this.itemFilters,
                        itemSorting: {
                            key: 'id',
                            direction: 'desc',
                        },
                    }, {path: ''});
                }
            } catch (e) {

            }
        },
        setStateToCookie () {
            this.$cookie.set(this.cookieName, {
                itemPagination: this.itemPagination,
                itemFilters: this.itemFilters,
                itemSorting: this.itemSorting,
            }, {path: ''});
        },
        loadStateFromCookie () {
            this.lazyCreateCookie();
            let cookie = this.$cookie.get(this.cookieName);
            let keys = ['itemPagination','itemFilters','itemSorting'];
            keys.forEach (key => {
                if (cookie[key]) {
                    this[key] = cookie[key];
                }
            });

            this.desiredFilters = this.itemFilters;
        },
        getLocalItemById(id) {
          let result = null;
          this.listData.items.forEach( (item) => {
              if (item.id === id) {
                  result = item;
                  return false;
              }
          });
          return result;
        },
        async deleteItem(item) {
            let finalItem = this.getLocalItemById(item.id);
            let result = await this.asyncOps.asyncCall(this.subject + '/' + finalItem.id , {}, {'method': 'delete'});

            if (result.isError) {
                this.$s.ui.notification(this.translate('sgirot.general.actionFailed'), 'error');
            } else {
                this.$s.ui.notification(this.translate('sgirot.general.actionSuccessful'), 'success');
            }

            this.refreshAsyncData();
        },
        promptDeleteItem (item) {
            this.$s.ui.modal.confirm('sgirot.general.deleteConfirmText').then(result => {
                this.deleteItem(item);
            }).catch(result => {
               //
            });
        },
        showItem(item) {
            this.$router.push({name: this.subject+'-itemId', params: {itemId: item.id}});
        },
        editItem(item) {
            this.$router.push({name: this.subject+'-edit-itemId', params: {itemId: item.id}});
        },
        getCellValue(col, value) {
            let cols = this.getDisplayColumnsByColumnProp(true);

            if ( ! cols.hasOwnProperty(col)) {
                return value;
            }

            if (typeof cols[col].cellFormatter === 'function') {
                return cols[col].cellFormatter(value);
            }

            return value;
        },
        columnIsDisplayedOnMobileBlock(col) {
            let cols = this.getDisplayColumnsByColumnProp(true);
            if ( ! cols.hasOwnProperty(col)) {
                return false;
            }

            return !! cols[col].showInMobileBlock
        },
        columnIsLinkable(col) {
            let cols = this.getDisplayColumnsByColumnProp(true);
            if ( ! cols.hasOwnProperty(col)) {
                return false;
            }

            return !! cols[col].isLinkable
        },
        setFilters (newFilters, replace = false) {
            if (replace) {
                this.desiredFilters = {...newFilters};
            } else {
                this.desiredFilters = {...this.desiredFilters, ...newFilters};
            }
        },
        updateLocalItem(itemId, updates) {
            // find the item
            let item = this.getLocalItemById(itemId);

            for (const [key, value] of Object.entries(updates)) {
                item[key] = value;
            }
        },
        getItemFilters() {
            return this.itemFilters;
        },
        refresh () {
            return new Promise ((fulfil, reject) => {
                this.refreshAsyncData();
                let stop = watchEffect(() => {
                    if (this.asyncOps.asyncStatus.loading === true) {
                        return;
                    }
                    stop();
                    fulfil();
                });
            });
        }
    },
    beforeUnmount() {

    },
    mounted () {
        if ( ! window) {
            return;
        }

        if (document.body.clientWidth < 760) {
            this.isMobile = true;
        }
    },
    watch : {
        listData: {
            handler : function () {
                this.deselectAllItems()
                this.$emit('list:dataUpdated', this.listData);
            },
            deep: true,
            immediate: false
        },
        itemFilters: { // updates actual filters with delay
            handler : function (oldVal, newVal) {
                this.$emit('list:filtersUpdated',  this.itemFilters)
            },
            deep: true,
            immediate: false
        },
        desiredFilters : { // updates actual filters with delay
            handler (oldVal, newVal) {
                clearTimeout(this.filterTimeout);

                this.aboutToLoadData = true;
                this.filterTimeout = setTimeout(() => {
                    this.itemFilters = {...this.desiredFilters};
                    this.aboutToLoadData = false;
                    this.setStateToCookie();
                }, this.filterDelay);
            },
            deep: true,
            immediate: false

        }
    }


}
</script>

<style scoped lang="scss">

    .rtl .uk-table th {
        text-align: right;
    }

    tr.sort-disabled th {
        cursor: default;
    }

    .item-action {
        font-size: 10px;
        background-color: var(--global-primary-background);
        color: var(--global-inverse-color);
        border-radius: 5px;
        padding: calc(var(--global-margin) * 0.25) calc(var(--global-margin) * 0.4);
        cursor: pointer;
        transition: opacity 150ms;



        &__text {
           margin-top: 2px;
        }

        &:hover {
            opacity: 0.8;
        }
    }

    .col-header-sortable {
        position: relative;
        cursor: pointer;
    }

    .col-header-id {
        min-width: 80px;
    }



    .filters {
        @media (max-width: 767px) {
            max-width: calc(50% - 10px);
        }
    }


    .filters-mobile {
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
    }

    .form-filter-field {
        @media (max-width: 767px) {
            width: calc(50% - 10px);

        }
    }

</style>
<style lang="scss">
// non scoped style for scoped slots
.sgirot-crud-list .item-action {
    font-size: 10px;
    background-color: var(--global-primary-background);
    color: var(--global-inverse-color);
    border-radius: 5px;
    padding: calc(var(--global-margin) * 0.25) calc(var(--global-margin) * 0.4);
    cursor: pointer;
    transition: opacity 150ms;

    &__text {
        margin-top: 2px;
    }

    &:hover {
        opacity: 0.8;
    }
}

.sgirot-crud-list{
    .filters-mobile {
        margin-top: 10px;
        display: flex;
        justify-content: flex-start;
    }

    .form-filter-field {
        @media (max-width: 767px) {
            width: calc(50% - 10px);
            min-width: calc(50% - 10px);
            flex: 0 0 calc(50% - 10px);

            .wrap {
                width: 100%!important;
            }

        }
    }
}

</style>
